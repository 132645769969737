<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">舆情记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <div class="InfoBox">
                <div class="name">{{ yqName }}</div>
                <div class="info">
                    <div class="time">
                        时间: {{ yqFbsj && yqFbsj.substring(0, 10) }}
                    </div>
                    <div class="source">来源: {{ yqLy }}</div>
                </div>
            </div>
            <div class="dealBox">
                <div class="title">处置信息</div>
                <div class="dealContent">
                    <div class="dealHead">
                        <div class="addBtn" @click="addDeal">+</div>
                        <div class="timebox">
                            <div
                                class="timeitem"
                                v-for="(item, index) in detailList"
                                :key="item.ID"
                                @click="changeDetail(item, index)"
                                :style="{
                                    color:
                                        activeNum == index ? '#409EFF' : '#ccc',
                                }"
                            >
                                <div
                                    class="point"
                                    :style="{
                                        background:
                                            activeNum == index
                                                ? '#409EFF'
                                                : '#ccc',
                                    }"
                                ></div>
                                {{ item.CZSJ }}
                            </div>
                        </div>
                    </div>
                    <div class="dealform">
                        <el-form
                            ref="formdata"
                            :inline="true"
                            :model="formdata.dataDic"
                            :rules="rules"
                            class="demo-form-inline form_row"
                            size="small"
                            label-width="200px"
                        >
                            <el-form-item
                                prop="CLMS"
                                label="处置措施"
                                class="from-item-block"
                            >
                                <el-input
                                    v-model="formdata.dataDic.CLMS"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                prop="CZSJ"
                                label="处置时间"
                                class="from-item-block"
                            >
                                <el-date-picker
                                    v-model="formdata.dataDic.CZSJ"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item
                                prop="CJRID"
                                label="处置人"
                                class="from-item-block"
                            >
                                <el-input
                                    v-model="formdata.dataDic.CJRID"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="相关附件"
                                class="from-item-block"
                            >
                                <div v-if="fileObj.FilePath" class="fileBox">
                                    <a
                                        class="fileName"
                                        :href="fileObj.FilePath"
                                        target="_blank"
                                        >{{
                                            fileObj.FileName || fileObj.FilePath
                                        }}</a
                                    >
                                    <div class="delBtn" @click="delFile">×</div>
                                </div>
                                <el-upload
                                    v-else
                                    :action="`${BASE_URL}UpLoad/FileSave?LJ=basicData`"
                                    :on-preview="handlePictureCardPreview"
                                    :on-success="uploadSuccess"
                                    :on-remove="handleRemove"
                                >
                                    <el-button size="small" type="primary"
                                        >点击上传</el-button
                                    >
                                </el-upload>
                            </el-form-item>
                            <div v-if="formdata.dataDic.ID" class="delBtn">
                                <el-button
                                    size="small"
                                    type="danger"
                                    @click="delDeal(formdata.dataDic.ID)"
                                    >删除
                                </el-button>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "yqjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "191",
                dataDic: {
                    CJRID: "", // 处置人
                    CLMS: "", // 处置措施
                    CZSJ: "", // 处置时间
                },
            },
            BASE_URL: window.REQUEST_URL,
            rules: {
                CLMS: [
                    {
                        required: true,
                        message: "请填写处置措施",
                        trigger: "blur",
                    },
                ],
                CJRID: [
                    {
                        required: true,
                        message: "请填写处置人",
                        trigger: "blur",
                    },
                ],
                CZSJ: [
                    {
                        required: true,
                        message: "请选择处置时间",
                        trigger: "blur",
                    },
                ],
            },
            YCSSCDList: [],
            SJLXList: [],
            showQTSJXLX: false,
            yqID: "", // 舆情id
            yqName: "", // 舆情名称
            yqFbsj: "", // 舆情发布时间
            yqLy: "", // 舆情来源
            detailList: [],
            fileObj: {
                FileName: "",
                FilePath: "",
                FilePath_ex: "",
                Guid: "",
            },
            activeNum: 0,
        };
    },
    mounted() {
        this.GetYqDetailList();
    },
    methods: {
        ...mapActions([
            "GetYqDetailData",
            "SavePublicSentimentDetails",
            "DeletePublicSentimentInfo",
            "DeleteYqFjInfo",
        ]),
        // 获得舆情详细信息
        async GetYqDetailList() {
            this.yqID = this.$route.query.ID;
            this.yqName = this.$route.query.yqName; // 舆情名称
            this.yqFbsj = this.$route.query.yqFbsj; // 舆情发布时间
            this.yqLy = this.$route.query.yqLy; // 舆情发布时间
            let res = await this.GetYqDetailData({
                id: this.yqID,
            });
            if (res.IsSuccess) {
                this.detailList = res.ResultValue || [];
            }
            console.log(
                res,
                this.yqName,
                this.yqFbsj,
                this.detailList,
                "获得舆情详细信息"
            );
            // let enumList = res || [];
            // enumList.forEach(v => {
            //     if (v.COLUMNID === 'SJLXVALUE') {
            //         this.SJLXList = v.dataItems;
            //     }
            //     if (v.COLUMNID === 'YCSSCDVALUE') {
            //         this.YCSSCDList = v.dataItems;
            //     }
            // });
        },
        // 查看详情
        changeDetail(row, index) {
            this.activeNum = index;
            console.log(row, "row11111");
            this.formdata.dataDic = row;
            this.fileObj.FileName = row.FJMC;
            this.fileObj.FilePath = row.FJLJ;
        },
        // 新增处置意见
        addDeal() {
            this.formdata.dataDic = {
                CJRID: "", // 处置人
                CLMS: "", // 处置措施
                CZSJ: "", // 处置时间
            };
            this.fileObj = {
                FileName: "",
                FilePath: "",
                FilePath_ex: "",
                Guid: "",
            };
        },
        // 删除处置意见
        async delDeal(id) {
            let res = await this.DeletePublicSentimentInfo({
                id: id,
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "删除成功",
                });
                this.addDeal();
                this.GetYqDetailList();
            }
            console.log(id, res, "删除处置意见");
        },
        async saveNosubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            Promise.all([basicValidPromise])
                .then(([basicValid]) => {
                    // 1205 一键上报修改数据即为审核通过
                    this.saveData();
                })
                .catch((e) => {
                    this.$message({
                        type: "warning",
                        message: "请检查是否填写完整！",
                    });
                });
        },
        saveSubmit() {
            this.saveNosubmit();
        },
        // 保存数据
        async saveData() {
            this.formdata.dataDic.YQID = this.yqID;
            let res = await this.SavePublicSentimentDetails({
                dataDic: JSON.stringify(this.formdata.dataDic),
                fileids: this.fileObj.Guid,
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.addDeal();
                this.GetYqDetailList();
            }
            console.log(res, "保存数据");
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {},
        uploadSuccess(response, file, fileList) {
            if (response.IsSuccess) {
                this.fileObj = response.ResultValue && response.ResultValue[0];
            }
        },
        // 删除附件
        async delFile() {
            let res = await this.DeleteYqFjInfo({
                id: this.formdata.dataDic.ID,
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "删除照片成功",
                });
                this.fileObj = {
                    FileName: "",
                    FilePath: "",
                    FilePath_ex: "",
                    Guid: "",
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.InfoBox {
    padding: 5px 10px;
    line-height: 40px;
    .name {
    }
    .info {
        display: flex;
        justify-content: flex-start;
        .time {
        }
        .source {
            margin-left: 20px;
        }
    }
}
.dealBox {
    .title {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        border-bottom: 1px #ccc solid;
    }
    .dealContent {
        .dealHead {
            display: flex;
            line-height: 40px;
            .addBtn {
                font-size: 24px;
                color: #03a9f4;
                width: 80px;
                text-align: center;
            }
            .timebox {
                flex: 1;
                border-top: 1px #ccc solid;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .timeitem {
                    flex: 1;
                    position: relative;
                    text-align: center;
                    .point {
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        border-radius: 6px;
                        top: -7px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .dealform {
            border: 1px #ccc solid;
            padding-right: 10px;
            .delBtn {
                display: flex;
                text-align: right;
                justify-content: flex-end;
                padding: 5px 10px;
            }
        }
        .fileBox {
            display: flex;
            justify-content: space-between;
            .fileName {
            }
            .delBtn {
                cursor: pointer;
                font-size: 24px;
                color: #03a9f4;
            }
        }
    }
}
</style>
